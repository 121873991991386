import jsQR from "jsqr";
import { BrowserQRCodeReader } from '@zxing/browser';
import * as pdfjsLib from "pdfjs-dist/legacy/build/pdf"
import {PDFPageView, EventBus} from "pdfjs-dist/legacy/web/pdf_viewer"

const MAX_SIZE = 1000.0

async function getCanvas(data_url) {
  const img = new Image()
  img.src = data_url

  await new Promise(resolve => img.addEventListener('load', () => resolve()))

  const largerSize = Math.max(img.width, img.height)
  const mult = Math.min(1, MAX_SIZE / largerSize)

  const canvas = document.createElement('canvas')
  canvas.width = img.width * mult
  canvas.height = img.height * mult
  
  const ctx = canvas.getContext('2d')
  ctx.imageSmoothingEnabled = false
  ctx.drawImage(img, 0, 0, canvas.width, canvas.height)

  return canvas
}


async function getImageDataFromCanvas(canvas) {
    return {
        data: canvas.getContext('2d').getImageData(0, 0, canvas.width, canvas.height),
        width: canvas.width,
        height: canvas.height
    }
  }

export async function extractQrText(data_url) {
  return extractQrTextFromCanvas(await getCanvas(data_url))
}

export async function extractQrTextFromCanvas(canvas) {
  const {data, width, height} = await getImageDataFromCanvas(canvas)

  const qrData = jsQR(data.data, width, height)

  if (!qrData) {
      return null
  }

  console.log("Decoded with jsQR")

  return {
      rawData: qrData.binaryData,
      stringData: qrData.data
  }
}

export async function zxExtractQrText(data_url) {
    const canvas = await getCanvas(data_url)

    return await zxExtractQrTextFromCanvas(canvas)
}

async function zxExtractQrTextFromCanvas(canvas) {
    const reader = new BrowserQRCodeReader()

    let result
    try {
        result = reader.decodeFromCanvas(canvas)
    } catch(e) {
        console.log(e)
        return null
    }

    console.log("Decoded with ZX")

    return {
        rawData: result.getRawBytes(),
        stringData: result.getText()
    }
}

export default async function allExtractQrText(data_url) {
    return await allExtractQrTextFromCanvas(await getCanvas(data_url))
}

export async function allExtractQrTextFromCanvas(canvas) {
    return await zxExtractQrTextFromCanvas(canvas) || await extractQrTextFromCanvas(canvas)
}

export async function extractFromPdf(data_url) {
    const eventBus = new EventBus();
    pdfjsLib.GlobalWorkerOptions.workerSrc = '/pdf.worker.min.js';
    const loadingTask = pdfjsLib.getDocument(data_url)
    const doc = await loadingTask.promise
    
    const container = document.createElement("div", {id: "pdf-container"})
    let viewer;

    const tickets = []

    console.log(doc)
    
    for (let i=1;i<=doc.numPages;i++) {
        const page = await doc.getPage(i)

        if (!viewer) {
            viewer = new PDFPageView({
                container,
                id: 1,
                scale: 1,
                defaultViewport: page.getViewport({ scale: 1 }),
                eventBus,
              });
        }

        viewer.setPdfPage(page)
        await viewer.draw()
        const ticket = await allExtractQrTextFromCanvas(viewer.canvas)
        if (ticket) {
            tickets.push(ticket)
        }
    }

    return tickets
}