const stationNameRegex = /\(\d+\) (.+)/
export function parseStationName(qrStationName) {
  return stationNameRegex.exec(qrStationName)[1]
}

const cityNameRegex = /\(\d+\) ([\u0400-\u04FF]+).*/
export function parseCityName(qrStationName) {
  return cityNameRegex.exec(qrStationName)[1]
}

const carRegex = /(\d+).+/
export function parseCar(qrCar) {
    return parseInt(carRegex.exec(qrCar)[1]).toString()
}

const seatRegex = /(\d+).+/
export function parseSeat(qrCar) {
    return parseInt(seatRegex.exec(qrCar)[1]).toString()
}