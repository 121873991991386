import './App.css';
import React, { useState } from 'react';
import moment from 'moment';
import extractQrText, { extractFromPdf } from './extractQrText'
import '@material/react-snackbar/dist/snackbar.css';
import '@material/react-top-app-bar/dist/top-app-bar.css';
import '@material/react-button/dist/button.css';
import '@material/react-card/dist/card.css';
import { Snackbar } from '@material/react-snackbar';
import { saveAs } from 'file-saver';
import { ReactComponent as AddToAppleWallet } from './add_to_wallet.svg';
import { parseCar, parseCityName, parseSeat, parseStationName } from './qrParsing';
import TopAppBar, { TopAppBarFixedAdjust, TopAppBarRow, TopAppBarSection, TopAppBarTitle } from '@material/react-top-app-bar';
import { Button } from '@material/react-button';
import Card, { CardActionButtons, CardActions, CardPrimaryContent } from '@material/react-card';
import { BACKEND_URL, DEBUG } from './config';
import { DEBUG_QR_DATA } from './debug';
import { useDropzone } from 'react-dropzone';

const UZ_FORMAT = "DD.MM hh:mm"

function getTicketInfo(qrCode) {
  let ticketData = qrCode.stringData.split('\n')

  console.log(ticketData)

  let passengerName = ticketData[9]
  let serial = ticketData[15]
  let seat = ""

  // animal
  if (ticketData[0]) {
    passengerName = ticketData[0]
    serial = ticketData[12]
  } else {
    seat = parseSeat(ticketData[7])
  }

  return {
    trainName: ticketData[1],
    departureCityName: parseCityName(ticketData[2]),
    destinationCityName: parseCityName(ticketData[3]),

    departureStationName: parseStationName(ticketData[2]),
    destinationStationName: parseStationName(ticketData[3]),

    departureTime: moment(ticketData[4], UZ_FORMAT),
    arrivalTime: moment(ticketData[5], UZ_FORMAT),

    car: parseCar(ticketData[6]),
    carFull: ticketData[6],

    seat: seat,
    seatFull: ticketData[7],

    passengerName: passengerName,

    serial: serial,
    rawQrData: qrCode.stringData
  }
}

async function savePkPass(ticketInfo) {
  const response = await fetch(BACKEND_URL + '/pkpass/create', {
    method: "POST",
    body: JSON.stringify(ticketInfo)
  })
  if (response.status !== 200) {
    return
  }

  const body = await response.blob()
  saveAs(body)
}

async function readAsDataURL(file) {
  const fr = new FileReader();
  return new Promise(resolve => {
    fr.addEventListener('load', function () { return resolve(String(fr.result)); });
    fr.readAsDataURL(file);
  })
}

function App() {
  const [message, setMessage] = useState();
  const [readingPdf, setReadingPdf] = useState(false)

  const showSnackbar = (message) => {
    setMessage(message);
    setTimeout(() => setMessage(null), 3000)
  }

  const [passes, setPasses] = useState([]);

  const onDrop = async (imageFiles) => {
    const passes = [];

    for (let imageFile of imageFiles) {
      const dataURL = await readAsDataURL(imageFile)

      if (imageFile.name.endsWith('pdf')) {
        setReadingPdf(true);
        const qrs = await extractFromPdf(dataURL)
        const tickets = qrs.map(qrText => getTicketInfo(qrText))
        passes.push(...tickets)
        setReadingPdf(false);
        continue
      } else {
        const qrText = await extractQrText(dataURL)
        if (!qrText) {
          showSnackbar("No QR found")
          return
        }

        passes.push(getTicketInfo(qrText))
      }
    }

    console.log(passes)
    setPasses(passes)
  };

  let snackbar = null;
  if (message) {
    snackbar = <Snackbar message={message} />
  }

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: ['image/jpeg', 'image/png', 'image/heic', 'image/heif', 'application/pdf'],
    onDrop
  })

  return (
    <div className="App">
      <TopAppBar>
        <TopAppBarRow>
          <TopAppBarSection>
            <TopAppBarTitle>UZ to Wallet</TopAppBarTitle>
          </TopAppBarSection>
        </TopAppBarRow>
      </TopAppBar>
      <TopAppBarFixedAdjust>
        {snackbar}

        <p>Генерація Apple Wallet Pass для квитків Укрзалізниці</p>
        <p>Підтримуються PDF/фото/скріншоти QR-коду з квитків</p>

        {readingPdf ? <p>Читаємо PDF...</p> : <div {...getRootProps()}>
          <input {...getInputProps()} />
          <Button
            raised
            style={isDragActive ? { color: 'red' } : undefined}
          >
            Завантажити QR-код
          </Button>
        </div>}

        <div style={{ marginTop: "24pt", marginLeft: "16pt", marginRight: "16pt", marginBottom: '24pt' }}>
          {DEBUG &&
            <AddToAppleWallet height='10mm' width="100%" onClick={() => savePkPass(getTicketInfo({ stringData: DEBUG_QR_DATA }))} />}

          {passes.length > 0 && <h4>Знайдено {passes.length} квитків</h4>}
          {passes.map(pass => (
            <Card key={pass.serial} style={{ marginTop: '16pt' }}>
              <CardPrimaryContent>
                <div>
                  <h3>{pass.departureCityName} - {pass.destinationCityName}</h3>
                  <h6>{pass.passengerName}</h6>
                </div>
              </CardPrimaryContent>
              <CardActions style={{ justifyContent: "right" }}>
                <CardActionButtons>
                  <AddToAppleWallet height='10mm' width="100%" onClick={() => savePkPass(pass)} />
                </CardActionButtons>
              </CardActions>
            </Card>
          ))}
        </div>
      </TopAppBarFixedAdjust>
    </div>
  );
}

export default App;
